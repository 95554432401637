<div [hidden]="selectedSpeaker"
    *ngIf="this.spService && this.spService.speakersList && this.spService.speakersList.length > 0"
    class="section text-center">
    <h2>Find your Women+ in Geospatial speaker today (total: {{this.spService.filteredSpeakersList.length}})</h2>
    <h3 *ngIf="this.spService.filteredSpeakersList.length == 0">Sorry, there is no profile fitting your request, try
        widening your search!</h3>
    <div>
        <ul class="nav nav-pills nav-pills-primary nav-pills-icons justify-content-center" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" href="#mapPane" role="tab" data-toggle="tab">
                    <i class="material-icons">map</i>
                    Map
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#listPane" role="tab" data-toggle="tab">
                    <i class="material-icons">dashboard</i>
                    List
                </a>
            </li>
        </ul>
    </div>

    <div class="row">
        <div class="col-md-3 text-left">
            <div class="form-group">
                <label class="heading" for="searchText">Filter results by text</label>
                <input type="text" class="form-control" id="searchText" placeholder="Search in name, bio, position ..."
                    [(ngModel)]="textSearch" (keyup)="filterAll()">
            </div>
            <button type="button" class="btn btn-link btn-xs btn-chevron"
                (click)="showSE.classList.contains('hidden') ? showSE.className = '' : showSE.className = 'hidden' ">
                <i [ngClass]="showSE.classList.contains('hidden')? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>

                <label class="heading" for="sector">&nbsp; Filter by sector</label>
            </button>
            <div #showSE>
                <div class="form-check ">
                    <label class="form-check-label">
                        <input class="form-check-input" name="sector" id="sector1" type="checkbox"
                            value="Private Company" [checked]="searchSector.private"
                            (change)="searchSector.private = !searchSector.private; filterAll()">
                        Private Company
                        <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" name="sector" id="sector2" type="checkbox"
                            value="Public sector / Government" [checked]="searchSector.public"
                            (change)="searchSector.public = !searchSector.public; filterAll()">
                        Public sector / Government
                        <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" name="sector" id="sector3" type="checkbox"
                            value="Non-governmental organisation (NGO) / Not-For-Profit organisation"
                            [checked]="searchSector.ngo" (change)="searchSector.ngo = !searchSector.ngo; filterAll()">
                        Non-governmental organisation (NGO) / Not-For-Profit organisation
                        <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" name="sector" id="sector4" type="checkbox"
                            value="Self-employed / Consulting" [checked]="searchSector.self"
                            (change)="searchSector.self = !searchSector.self; filterAll()">
                        Self-employed / Consulting
                        <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="sector" id="sector5" type="checkbox"
                            value="University / Academia" [checked]="searchSector.university"
                            (change)="searchSector.university = !searchSector.university; filterAll()">
                        University / Academia
                        <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" name="sector" id="sector6" type="checkbox"
                            value="International organisation" [checked]="searchSector.international"
                            (change)="searchSector.international = !searchSector.international; filterAll()">
                        International organisation
                        <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
            </div>
            <br>
            <button type="button" class="btn btn-link btn-xs btn-chevron"
                (click)="showYE.classList.contains('hidden') ? showYE.className = '' : showYE.className = 'hidden' ">
                <i [ngClass]="showYE.classList.contains('hidden')? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>

                <label class="heading" for="experience">&nbsp; Filter by years of work experience</label>
            </button>
            <div #showYE>
                <div class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="experience" id="experience1" type="radio"
                            value="0 - 5 years" (click)="textYears === '0 - 5 years'? textYears='':null; filterAll()"
                            (change)="filterAll()" [(ngModel)]="textYears">
                        0 - 5 years
                        <span class="circle">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="experience" id="experience2" type="radio"
                            value="5 - 10 years" (click)="textYears === '5 - 10 years'? textYears='':null; filterAll()"
                            (change)="filterAll()" [(ngModel)]="textYears">
                        5 - 10 years
                        <span class="circle">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="experience" id="experience3" type="radio"
                            value="10 - 20 years"
                            (click)="textYears === '10 - 20 years'? textYears='':null; filterAll()"
                            (change)="filterAll()" [(ngModel)]="textYears">
                        10 - 20 years
                        <span class="circle">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="experience" id="experience4" type="radio"
                            value="20 + years " (click)="textYears === '20 + years '? textYears='':null; filterAll()"
                            (change)="filterAll()" [(ngModel)]="textYears">
                        20 + years
                        <span class="circle">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
            </div>
            <br>
            <button type="button" class="btn btn-link btn-xs btn-chevron"
                (click)="showLE.classList.contains('hidden') ? showLE.className = '' : showLE.className = 'hidden' ">
                <!-- [tooltip]="showLO.classList.contains('hidden')? 'Show' : 'Hide'" -->
                <i [ngClass]="showLE.classList.contains('hidden')? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>

                <label class="heading" for="level">&nbsp; Filter by career level</label>
            </button>
            <div #showLE>
                <div class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="level" id="level1" type="radio" value="Early-career"
                            (click)="textLevel === 'Early-career'? textLevel='':null; filterAll()"
                            (change)="filterAll()" [(ngModel)]="textLevel">
                        Early-career
                        <span class="circle">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="level" id="level2" type="radio" value="Mid-level"
                            (click)="textLevel === 'Mid-level'? textLevel='':null; filterAll()" (change)="filterAll()"
                            [(ngModel)]="textLevel">
                        Mid-level
                        <span class="circle">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
                <div class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="level" id="level3" type="radio" value="Senior-level"
                            (click)="textLevel === 'Senior-level'? textLevel='':null; filterAll()"
                            (change)="filterAll()" [(ngModel)]="textLevel">
                        Senior-level
                        <span class="circle">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
            </div>
            <br>

            <button type="button" class="btn btn-link btn-xs btn-chevron"
                (click)="showAR.classList.contains('hidden') ? showAR.className = '' : showAR.className = 'hidden' ">
                <!-- [tooltip]="showLO.classList.contains('hidden')? 'Show' : 'Hide'" -->
                <i [ngClass]="showAR.classList.contains('hidden')? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>

                <label class="heading" for="level">&nbsp; Filter by area of expertise</label>
            </button>
            <div #showAR class="hidden">

                <div *ngFor="let ar of searchAreas | keyvalue" class="form-check form-check-radio">
                    <label *ngIf="typeOf(areasText[ar.key]) === 'string'" class="form-check-label">
                        <input class="form-check-input" name="area" type="checkbox" [checked]="ar.value"
                            (change)="searchAreas[ar.key] = !ar.value; filterAll()">
                        {{areasText [ar.key]}}

                        <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                    </label>

                    <!--          <span *ngFor="let arChild of ar.value | keyvalue">
                        <label *ngIf="typeOf(areasText[ar.key][arChild.key]) === 'string'" class="form-check-label">
                            <input class="form-check-input" name="area" type="checkbox" [checked]="ar.value"
                                (change)="searchAreas[ar.key][arChild.key] = !arChild.value; filterAll()">
                            {{areasText [ar.key][arChild.key]}}
                            <span class="form-check-sign">
                                <span class="check"></span>
                            </span>
                        </label>
                    </span> -->

                </div>
            </div>
            <br>

            <button type="button" class="btn btn-link btn-xs btn-chevron"
                (click)="showDO.classList.contains('hidden') ? showDO.className = '' : showDO.className = 'hidden' ">
                <!-- [tooltip]="showLO.classList.contains('hidden')? 'Show' : 'Hide'" -->
                <i [ngClass]="showDO.classList.contains('hidden')? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>

                <label class="heading" for="level">&nbsp; Filter by domain of expertise</label>
            </button>
            <div #showDO class="hidden">

                <div *ngFor="let dom of searchDomain | keyvalue" class="form-check form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" name="domain" type="checkbox" [checked]="dom.value"
                            (change)="searchDomain[dom.key] = !dom.value; filterAll()">
                        {{domainText [dom.key]}}
                        <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                    </label>
                </div>
            </div>


            <br>
            <button type="button" class="btn btn-link btn-xs btn-chevron"
                (click)="showLAN.classList.contains('hidden') ? showLAN.className = '' : showLAN.className = 'hidden' ">
                <i [ngClass]="showLAN.classList.contains('hidden')? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>

                <label class="heading" for="experience">&nbsp; Filter by presenting language</label>
            </button>
            <div #showLAN class="hidden">
                <div class="form-group">
                    <ng-select class="text-sm" [items]="languages" [multiple]="false" appendTo="body" name="languages"
                        [(ngModel)]="searchLanguages" (change)="filterAll()"></ng-select>
                </div>
            </div>
            <br>
            <button type="button" class="btn btn-link btn-xs btn-chevron"
                (click)="showWR.classList.contains('hidden') ? showWR.className = '' : showWR.className = 'hidden' ">
                <i [ngClass]="showWR.classList.contains('hidden')? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>

                <label class="heading" for="experience">&nbsp; Filter by world region</label>
            </button>
            <div #showWR class="hidden">
                <div class="form-group">
                    <ng-select class="text-sm" [items]="regions" [multiple]="false" appendTo="body" name="region"
                        [(ngModel)]="searchRegions" (change)="filterAll()"></ng-select>
                </div>
            </div>
            <br>
            <button class="btn btn-outline-primary btn-round" (click)="clearSearch()">Clear search</button>

        </div>
        <div class="col-md-9">

            <div class="tab-content tab-space">
                <div class="tab-pane active" id="mapPane">

                    <div *ngIf="this.spService.featureCollection" style="height: 600px;" id="mapDiv">

                      
                    </div>
                </div>

                <div class="tab-pane" id="listPane">
                    <div class="row">
                        <div *ngFor="let sp of this.spService.filteredSpeakersList.slice((currentPage-1) * LIMIT_PER_PAGE , currentPage * LIMIT_PER_PAGE ); let i=index"
                            class="col-md-3">
                            <div class="team-player">
                                <div class="card card-plain">
                                    <div class="col-md-6 ml-auto mr-auto">
                                        <img *ngIf="sp.picture != ''" src="{{sp.picture}}" loading="lazy"
                                            alt="{{sp.name}} Profile Picture W+G"
                                            class="img-raised rounded-circle img-fluid">
                                        <img *ngIf="sp.picture == ''" src="../../../assets/img/logo.png"
                                            alt="{{sp.name}} Profile Picture W+G"
                                            class="img-raised rounded-circle img-fluid">
                                    </div>
                                    <h4 class="card-title"> {{sp.name}}
                                        <br>
                                        <small class="card-description text-muted"> {{sp.position}}</small>
                                    </h4>
                                    <div class="card-body">
                                        <p class="card-description">{{sp.bio | slice:0:200}}...</p>
                                        <!--  <a routerLink="/speakers/{{ sp.id }}" href="#">See complete profile</a>-->
                                        <a (click)="seeProfile(sp.id)" class="text-primary">See complete profile</a>
                                    </div>
                                    <div class="card-footer justify-content-center">
                                        <a *ngIf="sp.contactLinkedIn" href="{{sp.linkedIn}}" target="_blank"
                                            class="btn btn-link btn-just-icon"><i class="fab fa-linkedin-in"></i></a>
                                        <a *ngIf="sp.contactTwitter" href="{{sp.twitter}}" target="_blank"
                                            class="btn btn-link btn-just-icon"><i class="fab fa-twitter"></i></a>
                                        <a *ngIf="sp.contactEmail" href="javascript:;" (click)="sendEmail(sp.email)"
                                            class="btn btn-link btn-just-icon"><i class="fa fa-envelope"></i></a>
                                        <a *ngIf="sp.webpage != '' && sp.webpage != null" href="{{sp.webpage}}"
                                            target="_blank" class="btn btn-link btn-just-icon"><i
                                                class="fas fa-globe-americas"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.spService.filteredSpeakersList" class="row">
                        <div class="col-md-6 ml-auto mr-auto">
                            <ngb-pagination [collectionSize]="this.spService.filteredSpeakersList.length"
                                [(page)]="currentPage" [maxSize]="5" [rotate]="true" [pageSize]="LIMIT_PER_PAGE">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Single Speaker details -->
<div *ngIf="selectedSpeaker" class="profile-content">
    <div class="container">
        <div class="row">
            <div class="col-md-3 ml-auto text-right">
                <button (click)="selectedSpeaker = null" type="button" class="btn btn-fab btn-round btn-primary">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ml-auto mr-auto">
                <div class="profile">
                    <div class="avatar">
                        <img *ngIf="selectedSpeaker && selectedSpeaker.picture != ''" src="{{selectedSpeaker.picture}}"
                            loading="lazy" alt="{{selectedSpeaker.name}} Profile Picture W+G"
                            class="img-raised rounded-circle img-fluid">
                        <img *ngIf="selectedSpeaker.picture == ''" src="../../../assets/img/logo.png"
                            alt="{{selectedSpeaker.name}} Profile Picture W+G"
                            class="img-raised rounded-circle img-fluid">
                    </div>
                    <div class="name">
                        <h3 class="title">{{selectedSpeaker.name}}</h3>
                        <h6 class="text-primary">{{selectedSpeaker.position}}</h6>
                        <h6>{{selectedSpeaker.city}}, {{selectedSpeaker.country}}</h6>
                        <div class="card-footer justify-content-center">
                            <a *ngIf="selectedSpeaker.contactLinkedIn" href="{{selectedSpeaker.linkedIn}}"
                                target="_blank" class="btn btn-link btn-just-icon"><i
                                    class="fab fa-linkedin-in"></i></a>
                            <a *ngIf="selectedSpeaker.contactTwitter" href="{{selectedSpeaker.twitter}}" target="_blank"
                                class="btn btn-link btn-just-icon"><i class="fab fa-twitter"></i></a>
                            <a *ngIf="selectedSpeaker.contactEmail" href="javascript:;"
                                (click)="sendEmail(selectedSpeaker.email)" class="btn btn-link btn-just-icon"><i
                                    class="fa fa-envelope"></i></a>
                            <a *ngIf="selectedSpeaker.webpage != '' && selectedSpeaker.webpage != null"
                                href="{{selectedSpeaker.webpage}}" target="_blank" class="btn btn-link btn-just-icon"><i
                                    class="fas fa-globe-americas"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <p class="bio">{{selectedSpeaker.bio}}</p>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-3 text-left">
                <div class="row">
                    <div class="col-md-3 ma">
                        <span class="material-icons">
                            device_hub
                        </span>
                    </div>
                    <div class="col-md-9">
                        <h6 class="heading">Sector(s)</h6>
                        <div *ngFor="let s of selectedSpeaker.sector | keyvalue">
                            <div *ngIf="s.value && s.key == 'private'">Private Company</div>
                            <div *ngIf="s.value && s.key == 'public'">Public sector / Government</div>
                            <div *ngIf="s.value && s.key == 'ngo'">Non-governmental organisation (NGO) / Not-For-Profit
                                organisation</div>
                            <div *ngIf="s.value && s.key == 'self'">Self-employed / Consulting</div>
                            <div *ngIf="s.value && s.key == 'university'">University / Academia</div>
                            <div *ngIf="s.value && s.key == 'international'">International organisation</div>
                            <div *ngIf="s.value && s.key == 'other'">Other: {{selectedSpeaker.otherSector}}</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 ma">
                        <span class="material-icons">
                            assignment_ind
                        </span>
                    </div>
                    <div class="col-md-9">
                        <h6 class="heading">Years of experience</h6>
                        {{selectedSpeaker.years}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 ma">
                        <span class="material-icons">
                            poll
                        </span>
                    </div>
                    <div class="col-md-9">
                        <h6 class="heading"> Career level</h6>
                        {{selectedSpeaker.level}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 ma">
                        <span class="material-icons">
                            present_to_all
                        </span>
                    </div>
                    <div class="col-md-9">
                        <h6 class="heading">I can present in </h6>
                        <span *ngFor="let lan of selectedSpeaker.languages">
                            {{lan}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-md-9 text-left">
                <div class="row">
                    <div class="col-md-1 ma">
                        <span class="material-icons">
                            public
                        </span>
                    </div>
                    <div class="col-md-11 text-left">
                        <h6 class="heading">Areas of expertise</h6>
                    </div>
                </div>
                <div class="row">
                    <div *ngFor="let a of areasClean" class="col-md-6 text-left">
                        <li>{{a}} </li>
                    </div>
                    <div *ngFor="let oa of selectedSpeaker.otherAreas" class="col-md-6 text-left">
                        <li>{{oa}} </li>
                    </div>
                </div>
                <!--  <div class="row">
                    <div *ngFor="let s of selectedSpeaker.newareas | keyvalue" class="col-md-6 text-left">
                        <li *ngIf="s.value && typeOf(areasText[s.key]) === 'string'">{{areasText[s.key]}} </li>
                        <div *ngFor="let sv of s.value | keyvalue">
                            <li *ngIf="sv.value && typeOf(areasText[s.key][sv.key]) === 'string'">
                                {{areasText[s.key][sv.key]}} </li>
                        </div>
                    </div>
                    <div *ngFor="let oa of selectedSpeaker.otherAreas" class="col-md-6 text-left">
                        <li>{{oa}} </li>
                    </div>
                </div> -->
                <br>
                <div class="row">
                    <div class="col-md-1 ma">
                        <span class="material-icons">
                            speaker_notes
                        </span>
                    </div>
                    <div class="col-md-11 text-left">
                        <h6 class="heading">Domains of expertise</h6>
                    </div>
                </div>
                <!--              <div class="row">
                    <span *ngFor="let d of selectedSpeaker.domain | keyvalue" class="col-md-6 text-left">
                        <li *ngIf="d.value">{{domainText[d.key]}} </li>
                    </span>
                    <span *ngFor="let od of selectedSpeaker.otherDomains">
                        <li *ngIf="od != ''">{{od}} </li>
                    </span>
                </div> -->
                <div class="row">
                    <div *ngFor="let d of domainsClean" class="col-md-6 text-left">
                        <li>{{d}} </li>
                    </div>
                    <div *ngFor="let od of selectedSpeaker.otherDomains">
                        <li *ngIf="od != ''">{{od}} </li>
                    </div>
                </div>
                <br>
                <div *ngIf="selectedSpeaker.qualifications && selectedSpeaker.qualifications != ''"
                    class="row text-left">
                    <div class="col-md-12 ml-auto mr-auto">
                        <div class="row">
                            <div class="col-md-1 ma">
                                <span class="material-icons">
                                    fact_check
                                </span>
                            </div>
                            <div class="col-md-11 text-left">
                                <h6 class="heading">Qualifications </h6>
                            </div>
                        </div>
                        <p>{{selectedSpeaker.qualifications}}</p>
                    </div>
                </div>
                <br>
                <div *ngIf="selectedSpeaker.speakingExperience && selectedSpeaker.speakingExperience != ''"
                    class="row text-left">
                    <div class="col-md-12 ml-auto mr-auto">
                        <div class="row">
                            <div class="col-md-1 ma">
                                <span class="material-icons">
                                    record_voice_over
                                </span>
                            </div>
                            <div class="col-md-11 text-left">
                                <h6 class="heading">Speaking experience </h6>
                            </div>
                        </div>
                        <p [innerHTML]="selectedSpeaker.speakingExperience"></p>
                    </div>
                </div>

            </div>
        </div>


        <!--  <hr>

        <div class="row text-center">
            <div class="col-md-6 ml-auto mr-auto">
                <h6>Sector(s)</h6>
                <div *ngFor="let s of selectedSpeaker.sector | keyvalue">
                    <div *ngIf="s.value && s.key == 'private'">Private Company</div>
                    <div *ngIf="s.value && s.key == 'public'">Public sector / Government</div>
                    <div *ngIf="s.value && s.key == 'ngo'">Non-governmental organisation (NGO) / Not-For-Profit
                        organisation</div>
                    <div *ngIf="s.value && s.key == 'self'">Self-employed / Consulting</div>
                    <div *ngIf="s.value && s.key == 'university'">University / Academia</div>
                    <div *ngIf="s.value && s.key == 'international'">International organisation</div>
                    <div *ngIf="s.value && s.key == 'other'">Other: {{selectedSpeaker.otherSector}}</div>
                </div>
            </div>
            <div class="col-md-6 ml-auto mr-auto">
                <h6> {{selectedSpeaker.years}} of experience</h6>
                <h6> Career level {{selectedSpeaker.level}}</h6>

            </div>
        </div>

        <div class="row text-center">
            <div class="col-md-12 ml-auto mr-auto">
                <h6>Areas of expertise</h6>
                <span *ngFor="let s of selectedSpeaker.newareas | keyvalue">
                    <span *ngIf="s.value && typeOf(areasText[s.key]) === 'string'">{{areasText[s.key]}} | </span>
                    <span *ngFor="let sv of s.value | keyvalue">
                        <span *ngIf="sv.value && typeOf(areasText[s.key][sv.key]) === 'string'">
                            {{areasText[s.key][sv.key]}} | </span>
                    </span>
                </span>
                <span *ngFor="let oa of selectedSpeaker.otherAreas">
                    <span>{{oa}} | </span>
                </span>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-12 ml-auto mr-auto">
                <h6>Domains of expertise</h6>
                <span *ngFor="let d of selectedSpeaker.domain | keyvalue">
                    <span *ngIf="d.value">{{domainText[d.key]}} | </span>
                </span>
                <span *ngFor="let od of selectedSpeaker.otherDomains">
                    <span>{{od}} | </span>
                </span>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-md-12 ml-auto mr-auto">
                <h6>I can present in </h6>
                <span *ngFor="let lan of selectedSpeaker.languages">
                    {{lan}}
                </span>
            </div>
        </div>

        <div *ngIf="selectedSpeaker.qualifications && selectedSpeaker.qualifications != ''" class="row text-center">
            <div class="col-md-12 ml-auto mr-auto">
                <h6>My qualifications </h6>
                <p>{{selectedSpeaker.qualifications}}</p>
            </div>
        </div>
        <div *ngIf="selectedSpeaker.speakingExperience && selectedSpeaker.speakingExperience != ''"
            class="row text-center">
            <div class="col-md-12 ml-auto mr-auto">
                <h6>My speaking experince </h6>
                <p>{{selectedSpeaker.speakingExperience}}</p>
            </div>
        </div> -->

        <br>
        <br>
        <br>

    </div>
</div>