<div *ngIf="isLoading" class="section text-center">
  <div class="row">
    <div class="col-md-8 col-sm-12 ml-auto mr-auto">
      <div class="section text-center">
        <div class="row">
          <div class="col-md-8 col-sm-12 ml-auto mr-auto">
            <i class="fas fa-spinner fa-pulse" style="color: #522d6d;
font-size: 3em;
text-align: center;
margin: auto;"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [hidden]="isLoading" class="section text-justify">
  <div class="row">
    <div class="col-md-8 col-sm-12 ml-auto mr-auto">
      <div class="card card-login">
        <form class="form" method="" action="">
          <div class="card-header card-header-primary text-center">
            <h4 class="card-title title">Become a speaker by login with:</h4>
            <div class="social-line">
              <a class="btn btn-just-icon btn-link" (click)="loginFB()">
                <i class="fab fa-facebook-square"></i>
              </a>
              <a class="btn btn-just-icon btn-link" (click)="loginTW()">
                <i class="fab fa-twitter"></i>
              </a>
              <a class="btn btn-just-icon btn-link" (click)="loginG()">
                <i class="fab fa-google"></i>
              </a>
            </div>
          </div>
          <div class="card-body" *ngIf="!isCreating">
            <p class="description text-center">Or use email / password</p>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons">mail</i>
                </span>
              </div>
              <input type="email" class="form-control" placeholder="Email..." name="emailInput"
                [(ngModel)]="emailInput">
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons">lock_outline</i>
                </span>
              </div>
              <input type="password" class="form-control" placeholder="Password..." name="pwdInput"
                [(ngModel)]="pwdInput">
            </div>
            <p class="description text-center text-danger"> {{errorSignIn}}</p>
            <div class="footer text-center">
              <a class="btn btn-primary btn-round btn-wd btn-lg btn-text-white" (click)="login()">Get Started</a> <br>
              <a class="btn btn-primary btn-link btn-wd btn-lg" (click)="recoverPwd()">Forgot Password?</a>
            </div>
            <p class="description text-center">If you dont have an account, create one:</p>
            <div class="footer text-center">
              <a class="btn btn-primary btn-link btn-wd btn-lg" (click)="isCreating=!isCreating">Create
                Account</a>
              <br>
            </div>
          </div>
          <div class="card-body" *ngIf="isCreating">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons">mail</i>
                </span>
              </div>
              <input type="email" class="form-control" placeholder="Email..." name="emailInputCreate"
                [(ngModel)]="emailInputCreate">
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons">lock_outline</i>
                </span>
              </div>
              <input type="password" class="form-control" placeholder="Password..." name="pwdInputCreate"
                [(ngModel)]="pwdInputCreate" (keyup)="checkPwd()">
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons">lock_outline</i>
                </span>
              </div>
              <input type="password" class="form-control" placeholder="Repeat password..." name="repPwdInputCreate"
                [(ngModel)]="repPwdInputCreate" (keyup)="checkPwd()">
            </div>
            <p class="description text-center text-danger"> {{errorSignUp}}</p>
            <div class="footer text-center">
              <a class="btn btn-primary btn-round btn-wd btn-lg btn-text-white" (click)="signUp()">Get Started</a> <br>
              <a class="btn btn-primary btn-link btn-wd btn-lg" (click)="isCreating=!isCreating">Back to log
                in</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>