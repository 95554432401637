<nav *ngIf="!routeMap" class="navbar navbar-color-on-scroll navbar-transparent fixed-top navbar-expand-lg" color-on-scroll="100">
  <div class="container">
    <div class="navbar-translate">
      <a class="navbar-brand" href="https://www.womeningeospatial.org">
        <div class="logo-image">
          <img src="../assets/img/logo.png" class="img-fluid">
        </div>
      </a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon"></span>
        <span class="navbar-toggler-icon"></span>
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="#" class="nav-link" routerLink="/speakers"> W+G Speakers</a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link" routerLink="/how"> How does it work?</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" rel="tooltip" title="" data-placement="bottom"
            href="https://www.google.com/url?q=https%3A%2F%2Ftwitter.com%2Fgeospatialwomen&sa=D&sntz=1&usg=AFQjCNFJiSofPUYfYkGYmDgg12VgmWrmow"
            target="_blank" data-original-title="Follow us on Twitter">
            <i class="fab fa-twitter"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" rel="tooltip" title="" data-placement="bottom"
            href="https://www.google.com/url?q=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fwomen-in-geospatial&sa=D&sntz=1&usg=AFQjCNGFALPE6qeQhnps98rMiuD99WQLYg"
            target="_blank" data-original-title="Follow us on LinkedIn">
            <i class="fab fa-linkedin"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" rel="tooltip" title="Get your Women+ in Geospatial swag" data-placement="bottom"
            href="https://www.google.com/url?q=https%3A%2F%2Fwww.redbubble.com%2Fpeople%2Fclearmappingco%2Fworks%2F39517764-women-in-geospatial-logo%3Fasc%3Du&sa=D&sntz=1&usg=AFQjCNECX3MjcY1-X4_bdrQz5OKfVwBYKw"
            target="_blank" data-original-title="Get your Women+ in Geospatial swag">
            <i class="fas fa-tshirt"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="isUser">
          <a routerLink="/register" href="#" class="nav-link"> <i
              class="fas fa-user"></i> My Profile</a>
        </li>
        <li class="nav-item">
          <a routerLink="/login" [hidden]="isUser" href="#" class="nav-link"> <i class="fas fa-user"></i> Log In</a>
          <a routerLink="/login" [hidden]="!isUser" href="#" class="nav-link" (click)="logOut()"><i class="fas fa-sign-out-alt"></i> Log Out</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div *ngIf="!routeMap" class="page-header header-filter" data-parallax="true" style="background-image: url('../assets/img/bg-pic.jpg')">
  <!--  style="background-color: #86339A;"  -->
  <div class="container">
    <div class="row">
      <div class="col-md-8 ml-auto mr-auto">
        <div class="brand text-center">
          <h1 class="title">Women+ in Geospatial Speakers Database</h1>
          <h3 class="title text-center">Be part of the W+G speakers database! Register, speak up and
            embrace change!
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!routeMap" class="main main-raised">
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>
<footer *ngIf="!routeMap" class="footer footer-default">
  <div class="container">
    <nav class="float-left">
      <ul>
        <li>
          &copy; 2022 Women+ in Geospatial
        </li>
      </ul>
    </nav>
    <div class="float-right">
      <a routerLink="/how">How does it work?</a>
      &nbsp;&nbsp;
      <a routerLink="/gdpr">Data privacy statement</a>
    </div>
  </div>
</footer>

<router-outlet *ngIf="routeMap" ></router-outlet>